import * as winston from 'winston';
import { Logger as _Logger } from 'winston';
import {
  currentBrand,
  currentEnvironment,
  isLocal,
  isPreviewMode,
} from '../ssr-helper';
const LogstashTransport = require('winston-logstash/lib/winston-logstash-latest');

type LoggerDefaults = {
  service: string;
  environment: string;
  type: string;
  status?: number;
  basket_id?: string;
  anonymousTrackingToken?: string;
};

export class Logger {
  private logger: _Logger;

  private isLocal = process.env.NEXT_PUBLIC_DEV_MODE ?? false;

  // initialize serviceName for publish mode
  private serviceName =
    (isPreviewMode ? 'previewApp-' : 'homepageApp-') +
    currentBrand.toLowerCase() +
    '-' +
    currentEnvironment.toLowerCase();

  private loggerDefaults: LoggerDefaults = {
    service: this.serviceName,
    environment: currentEnvironment,
    type: 'home_next_mvp_log',
  };

  constructor() {
    let logstashTransport = new winston.transports.Console({
      format: winston.format.combine(
        winston.format.colorize(),
        winston.format.simple()
      ),
    });

    if (!isLocal && process.env.LOGSTASH_HOST) {
      try {
        logstashTransport = new LogstashTransport({
          port: process.env.LOGSTASH_PORT ?? 4566,
          applicationName: this.loggerDefaults.service,
          host: process.env.LOGSTASH_HOST,
        });
      } catch (e) {
        console.error('Error while creating Logstash transport error.');
      }
    }

    this.logger = winston.createLogger({
      level: 'info',
      transports: [logstashTransport],
    });

    this.logger.on('error', () => {
      console.error('Error while logging, possibly due to logstash transport.');
    });
  }

  info = (msg: string) => {
    try {
      this.logger.info(msg, { ...this.loggerDefaults });
    } catch (e) {
      console.log(msg);
    }
  };

  error = (msg: string) => {
    try {
      this.logger.error(msg, { ...this.loggerDefaults });
    } catch (e) {
      console.error(msg);
    }
  };
}
