import { getContentPath } from '.';
import { processData } from './aem-utils';
import { PageHeader, getPageHeaderData } from './pageheader';
import { Logger } from './services/logging';
import { priceGrabPageContent } from './services/price-grab';
import {
  addGoogleAPIKeyToHotelsMap,
  addHotelsToHotelsCarousel,
  addHotelsToHotelsMap,
  addPropertyToComponent,
  modifyComponentsOfType,
} from './component-utils/utils';
import { Buffer } from 'buffer';
import getLocaleSubdomain from 'subdomains';

export enum ENVIRONMENTS {
  NONE = 'none',
  TEST1 = 'test1',
  TEST2 = 'test2',
  TEST3 = 'test3',
  TEST4 = 'test4',
  TEST5 = 'test5',
  UAT = 'uat',
  PROD = 'prod',
  LOCAL = 'local',
}
export enum BRANDS {
  NONE = 'none',
  VAA = 'VAA',
  VHOLS = 'VHOLS',
}

export enum AEM_MODES {
  PUBLISH = 'publish',
  PREVIEW = 'preview',
}

//! These constants should be used all over the app //
export const isPreviewMode =
  process.env.NEXT_PUBLIC_AEM_MODE?.toLowerCase() === AEM_MODES.PREVIEW;

export const assetPath = isPreviewMode ? '/preview/' : '';

export const currentEnvironment: ENVIRONMENTS =
  process.env.NEXT_PUBLIC_ENVIRONMENT_NAME?.toLowerCase() === 'grn'
    ? ENVIRONMENTS.PROD
    : (process.env.NEXT_PUBLIC_ENVIRONMENT_NAME?.toLowerCase() as ENVIRONMENTS);

export const currentBrand: BRANDS =
  process.env.NEXT_PUBLIC_BRAND_NAME?.toUpperCase() === 'VAA'
    ? BRANDS.VAA
    : BRANDS.VHOLS;

export const isTravelPlusHomepage =
  process.env.NEXT_PUBLIC_TPLUS?.toLowerCase() === 'true' || false;

export const isLocal = process.env.NEXT_PUBLIC_DEV_MODE ?? false;
//! --------- //

export interface PageData {
  status: 'error' | 'success' | 'redirect';
  errorCode?: number;
  errorMessage?: null | string;
  content?: any;
  pageHeader?: PageHeader | null;
  localeInfo: string;
}

export interface SearchPanelConfig {
  hideSearchPanel: boolean;
  destination?: string | null;
  bookingType?: string | null;
}

export default async function ssrHelper(
  route: string,
  locale: string = 'en-GB'
) {
  //! PREVIEW
  // https://test1-aem-author.webdev.vholsinternal.co.uk/content/vhols/gb/en.model.json
  // https://test1-viv.webdev.vholsinternal.co.uk/bin/vaahome/AEMPreviewDataExporter?brandPath=content/vhols/gb/en.model.json
  // https://test1-aem-author.webdev.vholsinternal.co.uk/content/vaa-home/gb/en.model.json
  // http://localhost:3000/preview?brandPath=content/launches/2023/11/08/preview_launch_testpage/content/vhols/global/en.model.json

  //! PUBLISH
  // https://test1-vholshome.webdev.vholsinternal.co.uk/gb/en.model.json
  // https://test1-vaahome.webdev.vholsinternal.co.uk/gb/en.model.json
  if (!isPreviewMode || !isTravelPlusHomepage) {
    if (getLocaleSubdomain(locale)) {
      return {
        status: 'success',
        errorCode: 0,
        errorMessage: null,
        content: null,
        pageHeader: null,
      };
    }
  }

  try {
    const backendUrl = getContentPath(route, locale);
    const headers = new Headers();

    new Logger().info(`Fetching data from: ${backendUrl}`);

    if (currentEnvironment === ENVIRONMENTS.LOCAL) {
      headers.append(
        'Authorization',
        'Basic ' + Buffer.from('admin' + ':' + 'admin').toString('base64')
      );
      headers.append('Cookie', 'cq-authoring-mode=TOUCH');
    }

    const res = await fetch(backendUrl, { headers });

    if (res.status === 200) {
      const aemData = await res.json();
      const pageContent = await processData(aemData);
      let pageTitle: string = '',
        description: string = '',
        canonicalUrl: string = '',
        searchPanelConfig: SearchPanelConfig = { hideSearchPanel: false };

      if (aemData.hasOwnProperty('vaaPageMetaData')) {
        pageTitle = aemData.vaaPageMetaData.pageTitle;
        description = aemData.vaaPageMetaData['jcr:description'];
        canonicalUrl = aemData.vaaPageMetaData['cq:canonicalUrl'];

        searchPanelConfig = {
          ...searchPanelConfig,
          ...(aemData.vaaPageMetaData.hasOwnProperty('hideSearchPanel') && {
            hideSearchPanel: aemData.vaaPageMetaData.hideSearchPanel,
          }),
          ...(aemData.vaaPageMetaData.hasOwnProperty('bookingType') && {
            bookingType: aemData.vaaPageMetaData.bookingType,
          }),
          ...(aemData.vaaPageMetaData.hasOwnProperty('destination') && {
            destination: aemData.vaaPageMetaData.destination,
          }),
        };
      }

      const pageContentWithPriceGrab = await priceGrabPageContent(pageContent);
      const pageContentWithHotels = await modifyComponentsOfType(
        pageContentWithPriceGrab,
        'vaa-home/components/hotels-carousel',
        addHotelsToHotelsCarousel
      );
      const pageContentWithHotelsCarouselRenderingMode =
        await modifyComponentsOfType(
          pageContentWithHotels,
          'vaa-home/components/hotels-carousel',
          (component: any) =>
            isPreviewMode
              ? addPropertyToComponent(component, 'clientSide', true)
              : component
        );
      const pageContentWithGoogleMapKey = await modifyComponentsOfType(
        pageContentWithHotelsCarouselRenderingMode,
        'vaa-home/components/hotels-map',
        addGoogleAPIKeyToHotelsMap
      );
      const pageContentWithHotelsMapHotels = await modifyComponentsOfType(
        pageContentWithGoogleMapKey,
        'vaa-home/components/hotels-map',
        addHotelsToHotelsMap
      );

      // successful fetch
      const pageData = {
        status: 'success',
        errorCode: 0,
        errorMessage: null,
        content: pageContentWithHotelsMapHotels,
        pageHeader: await getPageHeaderData(
          pageTitle,
          description,
          canonicalUrl,
          searchPanelConfig
        ),
        localeInfo: locale,
      };

      return pageData as PageData;
    }

    // res.status is not 200, means error, throw error
    const errorMessage = `Error while fetching data from: ${backendUrl}. Status: ${res.status}. Error: ${res.statusText}`;

    new Logger().error(errorMessage);
    throw new Error(errorMessage);
  } catch (e) {
    // There's an error in the above try block, throw error
    const errorMessage = `Error while fetching data. Error: ${e}`;

    new Logger().error(errorMessage);
    throw new Error(errorMessage);
  }
}
