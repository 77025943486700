import ValidateContentCardComponentProps from './content-card-component-validator';
import ValidateGalleryCardComponentProps from './gallery-card-component-validator';
import ValidateContentCardContainerComponentProps from './content-card-container-component-validator';
import ValidateCarouselComponentProps from './carousel-component-validator';
import ValidateTextLockupComponentProps from './text-lockup-component-validator';
import ValidateTailfinHeroBannerContainerComponentProps from './tailfin-hero-banner-component-validator';
import ValidateTailfinHeroBannerTwoContainerComponentProps from './tailfin-hero-banner-two-component-validator';
import ValidateIconPanelComponentProps from './icon-panel-component-validator';
import ValidateGlobalAlertBannerComponentProps from './global-alert-banner-component-validator';
import ValidateDestinationCarouselComponentProps from './destination-carousel-component-validator';
import ValidateCountdownBannerComponentProps from './countdown-banner-component-validator';
import ValidateHeadingComponentProps from './heading-component-validator';
import ValidateSpacerComponentProps from './spacer-component-validator';
import ValidatePromoBannerComponentProps from './promo-banner-component-validator';
import ValidateHotelsCarouselComponentProps from './hotels-carousel-component-validator';
import ValidateHotelsMapComponentProps from './hotels-map-component-validator';
import ValidateHeroBannerContainerComponentProps from './hero-banner-component-validator';
import ValidateSearchPanelComponentProps from './search-panel-component-validator';
import ValidateAccordionProps from './accordion-component-validator';
import ValidateLinkBannerProps from './link-banner-component-validator';
import ValidateBreadcrumbsProps from './breadcrumbs-component-validator';
import ValidateClimateChartComponentProps from './climate-chart-component-validator';
import ValidateTableCardProps from './table-card-component-validator';
import ValidateVideoComponentProps from './video-component-validator';

enum ValidationOutcomes {
  UNKNOWN = 'UNKNOWN',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  WARNING = 'WARNING',
}

interface ValidationResult {
  result: ValidationOutcomes;
  messages: string[];
  componentProps?: any;
}

export type { ValidationResult };

export {
  ValidationOutcomes,
  ValidateContentCardComponentProps,
  ValidateGalleryCardComponentProps,
  ValidateContentCardContainerComponentProps,
  ValidateCarouselComponentProps,
  ValidateTextLockupComponentProps,
  ValidateTailfinHeroBannerContainerComponentProps,
  ValidateIconPanelComponentProps,
  ValidateGlobalAlertBannerComponentProps,
  ValidateDestinationCarouselComponentProps,
  ValidateCountdownBannerComponentProps,
  ValidateHeadingComponentProps,
  ValidateSpacerComponentProps,
  ValidateTailfinHeroBannerTwoContainerComponentProps,
  ValidatePromoBannerComponentProps,
  ValidateHotelsCarouselComponentProps,
  ValidateHotelsMapComponentProps,
  ValidateHeroBannerContainerComponentProps,
  ValidateSearchPanelComponentProps,
  ValidateAccordionProps,
  ValidateLinkBannerProps,
  ValidateBreadcrumbsProps,
  ValidateTableCardProps,
  ValidateVideoComponentProps,
  ValidateClimateChartComponentProps,
};
