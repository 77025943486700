import { TextLockupComponent } from '@vaa-component-lib/component.molecule.text-lockup';
import { isOfTypeRating, validateComponentProps } from '../aem-utils/utils';
import ContainerComponent from 'src/components/container/container.component';
import { CarouselComponent, CarouselConfiguration } from '@virgin-atlantic/component-lib';
import ContentCardComponent from 'src/components/content-card/content-card.component';
import GalleryCardComponent from 'src/components/gallery-card/gallery-card.component';
import TableCard from 'src/components/table-card/table-card.component';
import { HTMLRenderer, PromoBannerComponent } from '@vaa-component-lib/component.molecule.promo-banner';
import ContentCardContainerComponent from 'src/components/content-card-container/content-card-container.component';
import { locationsService } from '../services/locations-service';
import { JSONArray, JSONObject } from '../api-utils/types';
import { holidaysService } from '../services/holidays-service';
import { Logger } from '../services/logging';
import VideoComponent from 'src/components/video-component/video-component.component';
import ClimateChartComponent from 'src/components/climate-chart/climate-chart.component';

export const renderComponent = (keyId: string, id: number = 0, componentProps: any) => {
    const resourceType = componentProps['sling:resourceType'];
    if (!resourceType) {
        return null;
    }
    const componentType = resourceType.split('/').pop();

    if (!componentType) {
        return null;
    }

    if(componentType === 'carousel' || componentType === 'container') {
        componentProps.children = Object.values(componentProps).filter((item: any) => item['sling:resourceType']);
    }

    if (componentType === 'content-card-container') {
        componentProps.cards = Object.values(componentProps).filter((item: any) => item['sling:resourceType']);
    }

    const vr = validateComponentProps(componentProps, componentType);

    if (vr.result === 'FAILURE') {
        console.error(`Component validation failed for ${componentType}: `, vr.messages);
        return null;
    }

    switch (componentType) {
        case 'text-lockup-component':
            return (
                <TextLockupComponent key={`${keyId}-${componentType}--${id}`} {...componentProps} >
                    {componentProps.children  && <HTMLRenderer htmlString={componentProps.children} />}
                </TextLockupComponent>);
        case 'container':
            if (!componentProps.children) {
                return null;
            }
            return (
                <ContainerComponent
                    key={`${keyId}-${componentType}--${id}`}
                    disablePadding={true}
                >
                    {componentProps.children.map((item: any, index: number) => renderComponent('container-content-item', index, item))}
                </ContainerComponent>);
        case 'carousel':
            if (!componentProps.children || componentProps.children.length === 0) {
                return null;
            }
            return (
                <CarouselComponent
                    key={`${keyId}-${componentType}--${id}`}
                    {...componentProps}
                    containedLayout={true}
                    configuration={CarouselConfiguration.Heavy}
                    extendedXlLayout={true}
                >
                    {componentProps.children.map((carouselItem: any, index: number) => renderComponent('carousel-item', index, carouselItem))}
                </CarouselComponent>
            );
        case 'content-card':
            return <ContentCardComponent key={`${keyId}-${componentType}--${id}`} {...componentProps} />;
        case 'destination-card':
            return <GalleryCardComponent key={`${keyId}-${componentType}--${id}`} {...componentProps} />;
        case 'promobanner':
            return <PromoBannerComponent key={`${keyId}-${componentType}--${id}`} {...componentProps} />;
        case 'climate-chart':
            return <ClimateChartComponent key={`${keyId}-${componentType}--${id}`} {...componentProps} />;
        case 'table-card':
            return <TableCard key={`${keyId}-${componentType}--${id}`} {...componentProps} />;
        case 'video-component':
            return <VideoComponent key={`${keyId}-${componentType}--${id}`} {...componentProps} />;
        case 'content-card-container':
            if (!componentProps.cards || componentProps.cards.length === 0) {
                return null;
            }
            const cards = componentProps.cards.map((card: any, index: number) => {
                return {
                    componentProps: card
                };
            });

            return (
                <ContentCardContainerComponent
                    key={`${keyId}-${componentType}--${id}`}
                    cards={cards}
                />
            );
        default:
            console.warn('Unable to render conmponent: ', componentType);
            return null;
    }
};

export const getLocationData = async (mimId: string) => {
    if (!mimId) {
        console.log('MIM ID not provided');
        return null;
    }
    const locationData = await locationsService.getById(mimId.trim());

    if (!locationData) {
        // console.log(`Unable to retrieve location data using MIM ID: '${mimId}' from locations service`);
        return null;
    }
    const locationItems = locationData['items'] as JSONArray;
    const firstLocation = locationItems[0] as JSONObject;
    const locationUrlDescription = firstLocation['locationUrlDescription'] as string;

    if (!locationUrlDescription) {
        console.log('Location Url Description not found from locations service');
        return null;
    }
    return locationUrlDescription;
};

export const getHotelData = async (location: string, clientSide: boolean = false) => {
    const isClientSide = clientSide && typeof window !== 'undefined';
    const holidaysData = await holidaysService.getHolidaysByLocation(location, isClientSide);

    if (!holidaysData) {
        console.log(`Unable to retrieve holidays data for location '${location}' from holidays service`);
        return null;
    }
    const locationObj = holidaysData['location'] as JSONObject;
    const locationDesc = locationObj['locationDescription'] as string;
    const holidays = holidaysData['holidays'] as JSONArray;

    const holidaysDataAmended = holidays.map((holiday: any) => {
        const { hotel } = holiday;
        const { tripAdvisor } = hotel;
        const { name, parentLocation, url, atAGlance, vRating, images, location } = hotel.content;

        const firstImage = `https:${images.filter((image: any) => image['RESULTS_CAROUSEL'])[0]['RESULTS_CAROUSEL'].url}`;
        const hasTripAdvisor = tripAdvisor ? true : false;
        const hasVrating = vRating && isOfTypeRating(vRating) ? true : false;

        return {
            name: name,
            location: parentLocation,
            image: {
                url: firstImage,
                alt: name,
            },
            details: atAGlance,
            link: {
                url: `destinations${url}-browse`,
                label: 'View details',
            },
            ...(hasVrating && { virgin: { rating: vRating } }),
            ...(hasTripAdvisor && {
                tripAdvisor: {
                    rating: tripAdvisor.rating,
                    reviews: tripAdvisor.numReviews
                }
            }),
            ...(location && {
                coordinates: {
                    longitude: location.lon,
                    latitude: location.lat
                }
            })
        };
    });

    return {
        hotels: holidaysDataAmended,
        location: locationDesc
    };
};

export async function addHotelsToHotelsCarousel(hotelsCarousel: any) {
    let location: string | null = '';
    let holidaysArr: any = [];
    let locationDescription = '';

    try {
        if (!hotelsCarousel.componentProps.mimId) {
            new Logger().info('MIM ID not provided');
            throw new Error('MIM ID not provided');
            return null;
        }

        location = await getLocationData(hotelsCarousel.componentProps.mimId);

    } catch (e) {
        new Logger().info(`ERROR: Unable to retrieve location: ${e}`);
    }

    try {
        if (!location) {
            new Logger().info('Location not provided');
            throw new Error('Location not provided');
            return null;
        }

        const hotelData = await getHotelData(location);
        if (!hotelData) {
            new Logger().info(`Unable to load Hotels Carousel for location: ${location}`);
            throw new Error(`Unable to load Hotels Carousel for location: ${location}`);
            return null;
        }

        holidaysArr = hotelData.hotels;
        locationDescription = hotelData.location;

    } catch (e) {
        new Logger().info(`ERROR: Unable to load Hotels Carousel: ${e}`);
    }

    return {
        isContainer: hotelsCarousel.isContainer,
        name: hotelsCarousel.name,
        componentType: hotelsCarousel.componentType,
        componentProps: {
            ...hotelsCarousel.componentProps,
            locationDescription,
            hotels: holidaysArr
        },
        gridInfo: hotelsCarousel.gridInfo
    };
}

export const addHotelsToHotelsMap = async (hotelsMap: any) => {
    let location: string | null = '';
    let hotelsArr: any = [];

    try {
        if (!hotelsMap.componentProps.mimId) {
            new Logger().info('MIM ID not provided');
            throw new Error('MIM ID not provided');
        }

        location = await getLocationData(hotelsMap.componentProps.mimId);

    } catch (e) {
        new Logger().info(`ERROR: Unable to retrieve location: ${e}`);
    }

    try {
        if (!location) {
            new Logger().info('Location not provided');
            throw new Error('Location not provided');
        }

        const hotelData = await getHotelData(location);
        if (!hotelData) {
            new Logger().info(`Unable to load Hotels Map for location: ${location}`);
            throw new Error(`Unable to load Hotels Map for location: ${location}`);
        }

        hotelsArr = hotelData.hotels;

    } catch (e) {
        new Logger().info(`ERROR: Unable to load Hotels Map: ${e}`);
    }

    return {
        isContainer: hotelsMap.isContainer,
        name: hotelsMap.name,
        componentType: hotelsMap.componentType,
        componentProps: {
            ...hotelsMap.componentProps,
            hotels: hotelsArr
        },
        gridInfo: hotelsMap.gridInfo
    };
};

export const addPropertyToComponent = (component: any, property: string, value: any) => {
    return {
        ...component,
        componentProps: {
            ...component.componentProps,
            [property]: value
        }
    };
};

export const addGoogleAPIKeyToHotelsMap = (map: any) => {
    const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
        new Logger().info('Google Maps API Key not provided');
        return null;
    }

    return {
        ...map,
        componentProps: {
            ...map.componentProps,
            apiKey
        }
    };
};

export const modifyComponentsOfType = async (content: any, componentType: string, modifyFunction: (component: any) => Promise<any>) => {
    const components = content[0].components;
    const componentsOfType = components.filter((component: any) => component.componentProps && component.componentProps['sling:resourceType'] === componentType);

    if (componentsOfType.length === 0) {
        new Logger().info(`No ${componentType} components found in page content`);
        return content;
    }

    const promises: Promise<any>[] = [];

    componentsOfType.forEach((component: any) => {
        promises.push(modifyFunction(component));
    });

    if (promises.length === 0) {
        new Logger().info(`No promises to resolve for ${componentType} components`);
        return content;
    }

    const componentsWithEnrichedData = await Promise.all(promises);

    componentsOfType.forEach((component: any, i: number) => {
        const resolvedComponent = componentsWithEnrichedData[i];
        if (!resolvedComponent) {
            new Logger().info(`Failed to resolve ${componentType}`);
            return;
        }
        components[components.indexOf(componentsOfType[i])] = resolvedComponent;
    });

    return content;
};
