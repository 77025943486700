import { objectHasFields } from 'src/utils/aem-utils/utils';
import {
  ValidationResult,
  ValidationOutcomes,
  ValidateTextLockupComponentProps,
  ValidateContentCardComponentProps,
  ValidateTableCardProps,
  ValidateVideoComponentProps,
} from '.';

export default function ValidateAccordionItemProps(
  accordionItemProps: any
): ValidationResult {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (!objectHasFields(accordionItemProps, ['collapsedLabel', 'index'])) {
    vr.messages.push('Accordion Item is missing collapsedLabel and/or index');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  if (accordionItemProps.hasOwnProperty('content')) {
    if (
      Array.isArray(accordionItemProps.content) &&
      accordionItemProps.content.length > 0
    ) {
      accordionItemProps.content.forEach(
        (componentContent: any, contentIndex: number) => {
          if (!componentContent['sling:resourceType']) {
            return;
          }
          const componentType = componentContent['sling:resourceType']
            .split('/')
            .pop();

          switch (componentType) {
            case 'text-lockup-component':
              const textLockupValidationResult =
                ValidateTextLockupComponentProps(componentContent);
              break;
            case 'content-card':
              const contentCardValidationResult =
                ValidateContentCardComponentProps(componentContent);
              break;
            case 'table-card':
              const tableCardValidationResult =
                ValidateTableCardProps(componentContent);
              break;
            case 'video-component':
              const videoComponentValidationResult =
                ValidateVideoComponentProps(componentContent);
              break;
            default:
              break;
          }
        }
      );
    }
  }

  vr.componentProps = accordionItemProps;
  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
}
