import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';
import isButtonPropValid from './button-property-validator';
import isImagePropValid from './image-property-validator';

const ValidateHeroBannerContainerComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };
  const SECONDARY_CONTENT = 'secondaryContent';

  if (!objectHasFields(props, ['title', 'desktopImage', 'mobileImage'])) {
      vr.messages.push('HeroBanner is missing required fields');
      vr.result = ValidationOutcomes.FAILURE;
      return vr;
  }

  const vaaDesktopImageValidationResult = isImagePropValid(props.desktopImage);
  const vaaMobileImageValidationResult = isImagePropValid(props.mobileImage);
  let vahDesktopImageValidationResult = null;
  let vahMobileImageValidationResult = null;

  if (props.hasOwnProperty(SECONDARY_CONTENT)) {
    vahDesktopImageValidationResult = isImagePropValid(props[SECONDARY_CONTENT].desktopImage);
    vahMobileImageValidationResult = isImagePropValid(props[SECONDARY_CONTENT].mobileImage);
  }

  if (vaaDesktopImageValidationResult.result === ValidationOutcomes.FAILURE || vaaMobileImageValidationResult.result === ValidationOutcomes.FAILURE) {
    vr.messages = vr.messages.concat(vaaDesktopImageValidationResult.messages);
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  } else if (vaaDesktopImageValidationResult.result === ValidationOutcomes.WARNING) {
    vr.result = ValidationOutcomes.WARNING;
    vr.messages = vr.messages.concat(vaaDesktopImageValidationResult.messages);
    props.desktopImage = vaaDesktopImageValidationResult.componentProps;
    props.mobileImage = vaaMobileImageValidationResult.componentProps;
  } else {
    vr.result = ValidationOutcomes.SUCCESS;
    vr.messages = vr.messages.concat(vaaDesktopImageValidationResult.messages);
    props.desktopImage = vaaDesktopImageValidationResult.componentProps;
    props.mobileImage = vaaMobileImageValidationResult.componentProps;
  }

  if (vahDesktopImageValidationResult && vahMobileImageValidationResult && vahDesktopImageValidationResult.result === ValidationOutcomes.WARNING) {
    vr.result = ValidationOutcomes.WARNING;
    vr.messages = vr.messages.concat(vahDesktopImageValidationResult.messages);
    props[SECONDARY_CONTENT].desktopImage = vahDesktopImageValidationResult.componentProps;
    props[SECONDARY_CONTENT].mobileImage = vahMobileImageValidationResult.componentProps;

  } else {
    if (vahDesktopImageValidationResult && vahMobileImageValidationResult) {
      vr.result = ValidationOutcomes.SUCCESS;
      vr.messages = vr.messages.concat(vahDesktopImageValidationResult.messages);
      props[SECONDARY_CONTENT].desktopImage = vahDesktopImageValidationResult.componentProps;
      props[SECONDARY_CONTENT].mobileImage = vahMobileImageValidationResult.componentProps;
    }
  }

  if (props.hasOwnProperty('buttons') && props.buttons.length > 0) {
    const buttons = props.buttons;
    buttons.forEach((button: any, index: number, o: any) => {
      const buttonValidationResult = isButtonPropValid(button);
      if (buttonValidationResult.result === ValidationOutcomes.FAILURE) {
        vr.result = ValidationOutcomes.WARNING;
        vr.messages = vr.messages.concat(buttonValidationResult.messages);
        vr.messages.push('Invalid button data, button deleted');
        props.buttons.splice(index, 1);
      }
    });
  }

  vr.componentProps = props;
  return vr;
};

export default ValidateHeroBannerContainerComponentProps;
