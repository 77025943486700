import { ValidationOutcomes, ValidationResult } from '.';

const ValidateVideoComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (!props.title || typeof props.title !== 'string') {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('Invalid or missing title.');
    return vr;
  }

  if (!props.videoUrl || typeof props.videoUrl !== 'string') {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('Invalid or missing videoUrl.');
    return vr;
  }

  if (
    !props.posterImage ||
    !props.posterImage.url ||
    typeof props.posterImage.url !== 'string'
  ) {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('Invalid or missing posterImage url.');
    return vr;
  }

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = props;
  return vr;
};

export default ValidateVideoComponentProps;
