import { ESIData } from 'src/components/page-esi-wrapper/page-esi-wrapper.component';
import { ValidationResult, ValidationOutcomes } from '.';
import { isObjectEmpty } from '../utils/aem-utils/utils';

const ValidateRouteMapComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (isObjectEmpty(props)) {
    vr.messages.push('Route Map is empty');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.componentProps = props;

  const esiData = ESIData(false, null, null, 'en-GB');
  let routeMapESI = esiData.VAA.routeMap.esi;

  if (routeMapESI === '') {
    vr.messages.push('Route Map version is invalid');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.componentProps['ESIString'] = routeMapESI;

  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
};

export default ValidateRouteMapComponentProps;
