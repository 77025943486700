import { ESIData } from 'src/components/page-esi-wrapper/page-esi-wrapper.component';
import { ValidationResult, ValidationOutcomes } from '.';
import { hasPropertyAndEquals, isObjectEmpty, replaceString } from '../utils/aem-utils/utils';
import { has } from 'cypress/types/lodash';

const getSearchESI = (
  version: string,
  destination: string | null = null,
  bookingType: string | null = null
): string => {
  let esiString: string = '';
  const esiData = ESIData(false, destination, bookingType, 'en-GB');
  switch (version) {
    case 'V1':
      esiString = esiData.VHOLS.search.esi;
      break;
    case 'V2':
      esiString = esiData.VAA.search.esi;
      break;
    case 'V3':
      esiString = esiData.VAA.search.esi;
      break;
    default:
      esiString = '';
      break;
  }
  return esiString;
};

const ValidateSearchPanelComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (isObjectEmpty(props)) {
    vr.messages.push('Search Panel is empty');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  if (!props.hasOwnProperty('version')) {
    vr.messages.push('Search Panel is missing version property');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.componentProps = props;
  const { version, destination, bookingType } = props || {};

  let searchESI = getSearchESI(version, destination, bookingType);

  if (searchESI === '') {
    vr.messages.push('Search Panel version is invalid');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // V1 - Virgin Holidays
  if (props['version'] === 'V1') {
    vr.componentProps['displayButton'] = true;
  }

  // V2 - Virgin Atlantic
  if (props['version'] === 'V2') {
    if (hasPropertyAndEquals(props, 'open', false)) {
      searchESI = replaceString(searchESI, 'open=true', 'open=false');
    }
    if (hasPropertyAndEquals(props, 'closable', true)) {
      searchESI = replaceString(searchESI, 'closable=false', 'closable=true');
    }

    vr.componentProps['displayButton'] = false;
  }

  // V3 - Virgin Atlantic
  if (props['version'] === 'V3') {
    const { openState, hideToggle, optionsType } = props || {};

    if (openState === false) {
      searchESI = replaceString(searchESI, 'open=true', 'open=false');
    }
    if (hideToggle === true) {
      searchESI = replaceString(searchESI, 'hideToggle=false', 'hideToggle=true');
    }

    const mapping: { [key: string]: string } = {
      FLIGHT_ONLY: 'flightOnly',
      REWARD_FLIGHT: 'rewardFlight',
      HOLIDAY: 'holiday',
      FLYDRIVE: 'flyDrive',
      MULTICENTRE: 'multiCentre',
    };

    if (optionsType in mapping) {
      const key = mapping[optionsType as keyof typeof mapping];

      const isHolidayType = ['HOLIDAY', 'FLYDRIVE', 'MULTICENTRE'].includes(
        optionsType
      );
      const isFlightType = ['FLIGHT_ONLY', 'REWARD_FLIGHT'].includes(
        optionsType
      );
      searchESI = replaceString(searchESI, 'type=FLIGHT_ONLY', `type=${optionsType}`);

      if (isFlightType && props.hasOwnProperty(key)) {
        const { destination, origin } = props[key] || {};
        const flight_replace_strs = [
          '&criteria:destination=JFK',
          '&criteria:origin=LHR',
          '&criteria:gateway=MAN',
          '&criteria:location=new-york',
        ];
        const flight_replacements = [
          `&criteria:destination=${destination}`,
          `&criteria:origin=${origin}`,
          '',
          '',
        ];
        for (let i = 0; i < flight_replace_strs.length; i++) {
          searchESI = replaceString(searchESI, flight_replace_strs[i], flight_replacements[i]);
        }
      }

      if (isHolidayType && props.hasOwnProperty(key)) {
        const { location, gateway } = props[key] || {};
        if (location)
          searchESI = replaceString(searchESI, 'location=new-york', `location=${location}`);
        if (gateway) {
          searchESI = replaceString(searchESI, 'gateway=MAN', `gateway=${gateway}`);
        }
        const holiday_replace_strs = [
          '&criteria:destination=JFK',
          '&criteria:origin=LHR',
        ];
        const holiday_replacements = [
          '',
          '',
        ];
        for (let i = 0; i < holiday_replace_strs.length; i++) {
          searchESI = replaceString(searchESI, holiday_replace_strs[i], holiday_replacements[i]);
        }
      }

      if (optionsType === 'MULTICENTRE' && props.hasOwnProperty(key)) {
        const { location } = props[key] || {};
        if (location) {
          searchESI = replaceString(searchESI, 'location=new-york', `location=${location}`);
        }
        const multiCentre_replace_strs = [
          '&criteria:destination=JFK',
          '&criteria:origin=LHR',
          '&criteria:gateway=MAN',
        ];
        const multiCentre_replacements = [
          '',
          '',
          '',
        ];
        for (let i = 0; i < multiCentre_replace_strs.length; i++) {
          searchESI = replaceString(searchESI, multiCentre_replace_strs[i], multiCentre_replacements[i]);
        }
      }
    }
  }

  vr.componentProps['ESIString'] = searchESI;

  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
};

export default ValidateSearchPanelComponentProps;
