const SEARCH_PANEL_EVENT = 'SEARCH_PANEL_BRAND_CHANGE';

function subscribe(eventName: string, listener: (this: Document, ev: any) => any) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: string, listener: (this: Document, ev: any) => any) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: string, data: any) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { subscribe, unsubscribe, publish, SEARCH_PANEL_EVENT };