import { ENVIRONMENTS, currentEnvironment } from 'src/utils/ssr-helper';

interface Subdomain {
  locale: string;
  description: string;
  url?: {
    test: string;
    prod: string;
  };
}

// Sorted according to the order of the locales in the language selector (header)
const subdomains: Subdomain[] = [
  {
    locale: 'zh-CN',
    description: 'Chinese (China)',
    url: {
      test: 'https://stg-zh.virginatlantic.com/cn/zh',
      prod: 'https://zh.virginatlantic.com/cn/zh',
    },
  },
  {
    locale: 'ar-AE',
    description: 'Arabic (United Arab Emirates)',
    url: {
      test: 'https://stg-ar.virginatlantic.com/ae/ar',
      prod: 'https://ar.virginatlantic.com/ae/ar',
    },
  },
  {
    locale: 'es-US',
    description: 'Spanish (United States)',
    url: {
      test: 'https://stg-es.virginatlantic.com/us/es',
      prod: 'https://es.virginatlantic.com/us/es',
    },
  },
];

export function getLocales(): string[] {
  return subdomains.map((subdomain) => subdomain.locale);
}

function getLocaleSubdomain(locale: string): string | null {
  const subdomain = subdomains.find(
    (subdomain: any) => subdomain.locale.toLowerCase() === locale.toLowerCase()
  );
  if (subdomain) {
    if (!subdomain.hasOwnProperty('url')) {
      const region = locale.split('-')[1].toLowerCase();
      const language = locale.split('-')[0].toLowerCase();
      const url = `https://${
        currentEnvironment === ENVIRONMENTS.PROD ? 'www' : 'stg'
      }.virginatlantic.com/${region}/${language}`;
      return url;
    } else {
      if (currentEnvironment === ENVIRONMENTS.PROD) return subdomain.url!.prod;
      return subdomain.url!.test;
    }
  }
  return null;
}

export default getLocaleSubdomain;
